import React from "react"
import { Link } from "gatsby"

import DocumentLayout from "../../components/layout/document"

import ChatIcon from "../../../static/svgs/chat.svg"
import StreetSignIcon from "../../../static/svgs/streetsign.svg"
import LayerIcon from "../../../static/svgs/layers.svg"

export default () => {
  return (
    <DocumentLayout title="Introduction">
      <div className="headline">
        <h1 className="text-primary">Introduction</h1>
      </div>

      <h2 className="h3">Welcome to the Liquid Open APIs</h2>
      <p>
        Liquid Open API Platform provides a collection of API to help you build
        scalable and simple integration for a wide range of use cases on mobile
        applications.
      </p>
      <p>
        We are excited to see what kind of customer payment journey you can
        create with Liquid Open APIs.
      </p>

      <h2 className="h3">Start the journey</h2>
      <a className="btn btn-primary-outline" href="/docs/getting-started">
        Let's go
      </a>
      <h2 className="h3">Developer Support</h2>
      <div className="row">
        <div className="col-md-3">
          <Link to="/support/contact-us" className="nounderline">
            <div className="box box-primary text-center">
              <img
                src={ChatIcon}
                height="60px"
                style={{ margin: "8px" }}
                alt="icon"
              />
              <h4 className="text-primary">Contact Us</h4>
            </div>
          </Link>
        </div>
        <div className="col-md-3">
          <Link to="/support/faq" className="nounderline">
            <div className="box box-primary text-center">
              <img
                src={StreetSignIcon}
                className="text-primary"
                height="60px"
                style={{ margin: "8px" }}
                alt="icon"
              />
              <h4 className="text-primary">FAQs</h4>
            </div>
          </Link>
        </div>
      </div>

      <h2 className="h3">Learn more about our APIs</h2>
      <div className="row">
        <div className="col-md-3">
          <Link to="/support/faq" className="nounderline">
            <div className="box box-primary text-center">
              <img
                src={LayerIcon}
                className="text-primary"
                height="60px"
                style={{ margin: "8px" }}
                alt="icon"
              />
              <h4 className="text-primary">API Explorer</h4>
            </div>
          </Link>
        </div>
      </div>
    </DocumentLayout>
  )
}
